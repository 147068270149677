import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca sign`}</strong>{` -- generate a new certificate signing a certificate request`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca sign <csr-file> <crt-file>
[--token=<token>] [--issuer=<name>] [--provisioner-password-file=<file>]
[--not-before=<time|duration>] [--not-after=<time|duration>]
[--set=<key=value>] [--set-file=<file>]
[--acme=<uri>] [--standalone] [--webroot=<file>]
[--contact=<email>] [--http-listen=<address>] [--console]
[--x5c-cert=<file>] [--x5c-key=<file>] [--k8ssa-token-path=<file>]
[--offline] [--password-file=<file>] [--ca-url=<uri>]
[--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca sign`}</strong>{` command signs the given csr and generates a new certificate.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`csr-file`}</inlineCode>{`
File with the certificate signing request (PEM format)`}</p>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
File to write the certificate (PEM format)`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--token`}</strong>{`=`}<inlineCode parentName="p">{`token`}</inlineCode>{`
The one-time `}<inlineCode parentName="p">{`token`}</inlineCode>{` used to authenticate with the CA in order to create the
certificate.`}</p>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--not-before`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period starts. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--not-after`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period ends. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pair with template data variables. Use the `}<strong parentName="p">{`--set`}</strong>{` flag multiple times to add multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--console`}</strong>{`
Complete the flow while remaining inside the terminal`}</p>
    <p><strong parentName="p">{`--kms`}</strong>{`=`}<inlineCode parentName="p">{`uri`}</inlineCode>{`
The `}<inlineCode parentName="p">{`uri`}</inlineCode>{` to configure a Cloud KMS or an HSM.`}</p>
    <p><strong parentName="p">{`--x5c-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--x5c-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'x5c' header.`}</p>
    <p><strong parentName="p">{`--x5c-chain`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{`, in PEM format`}</p>
    <p><strong parentName="p">{`--nebula-cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{` in PEM format to store in the 'nebula' header of a JWT.`}</p>
    <p><strong parentName="p">{`--nebula-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the certificate that will
be stored in the 'nebula' header.`}</p>
    <p><strong parentName="p">{`--acme`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
ACME directory `}<inlineCode parentName="p">{`url`}</inlineCode>{` to be used for requesting certificates via the ACME protocol.
Use this flag to define an ACME server other than the Step CA. If this flag is
absent and an ACME provisioner has been selected then the '--ca-url' flag must be defined.`}</p>
    <p><strong parentName="p">{`--standalone`}</strong>{`
Get a certificate using the ACME protocol and standalone mode for validation.
Standalone is a mode in which the step process will run a server that will
will respond to ACME challenge validation requests. Standalone is the default
mode for serving challenge validation requests.`}</p>
    <p><strong parentName="p">{`--webroot`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Specify a `}<inlineCode parentName="p">{`file`}</inlineCode>{` to use as a 'web root' for validation in the ACME protocol.
Webroot is a mode in which the step process will write a challenge file to a
location being served by an existing fileserver in order to respond to ACME
challenge validation requests.`}</p>
    <p><strong parentName="p">{`--contact`}</strong>{`=`}<inlineCode parentName="p">{`email-address`}</inlineCode>{`
The `}<inlineCode parentName="p">{`email-address`}</inlineCode>{` used for contact as part of the ACME protocol. These contacts
may be used to warn of certificate expiration or other certificate lifetime events.
Use the '--contact' flag multiple times to configure multiple contacts.`}</p>
    <p><strong parentName="p">{`--http-listen`}</strong>{`=`}<inlineCode parentName="p">{`address`}</inlineCode>{`
Use a non-standard http `}<inlineCode parentName="p">{`address`}</inlineCode>{`, behind a reverse proxy or load balancer, for
serving ACME challenges. The default address is :80, which requires super user
(sudo) privileges. This flag must be used in conjunction with the '--standalone'
flag.`}</p>
    <p><strong parentName="p">{`--k8ssa-token-path`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Configure the `}<inlineCode parentName="p">{`file`}</inlineCode>{` from which to read the kubernetes service account token.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Sign a new certificate for the given CSR:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ TOKEN=$(step ca token internal.example.com)
$ step ca sign --token $TOKEN internal.csr internal.crt
`}</code></pre>
    <p>{`Sign a new certificate with a 1h validity:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ TOKEN=$(step ca token internal.example.com)
$ step ca sign --token $TOKEN --not-after=1h internal.csr internal.crt
`}</code></pre>
    <p>{`Sign a new certificate using the offline mode, requires the configuration
files, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca sign --offline internal internal.csr internal.crt
`}</code></pre>
    <p>{`Sign a new certificate using the offline mode with additional flag to avoid
console prompts:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca sign --offline --password-file ./pass.txt internal internal.csr internal.crt
`}</code></pre>
    <p>{`Sign a new certificate using an X5C provisioner:
NOTE: You must have a X5C provisioner configured (using `}<strong parentName="p">{`step ca provisioner add`}</strong>{`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca sign foo.internal foo.csr foo.crt --x5c-cert leaf-x5c.crt --x5c-key leaf-x5c.key
`}</code></pre>
    <p><strong parentName="p">{`Certificate Templates`}</strong>{` - With a provisioner configured with a custom
template we can use the `}<strong parentName="p">{`--set`}</strong>{` flag to pass user variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca sign foo.csr foo.crt --set dnsNames=foo.internal.com
$ step ca sign foo.csr foo.crt --set dnsNames='["foo.internal.com","bar.internal.com"]'
`}</code></pre>
    <p>{`Or you can pass them from a file using `}<strong parentName="p">{`--set-file`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat path/to/data.json
{
  "dnsNames": ["foo.internal.com","bar.internal.com"]
}
$ step ca sign foo.csr foo.crt --set-file path/to/data.json
`}</code></pre>
    <p><strong parentName="p">{`step CA ACME`}</strong>{` - In order to use the step CA ACME protocol you must add a
ACME provisioner to the step CA config. See `}<strong parentName="p">{`step ca provisioner add -h`}</strong>{`.`}</p>
    <p>{`Sign a CSR using the step CA ACME server and a standalone server
to serve the challenges locally (standalone mode is the default):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca sign foo.csr foo.crt --provisioner my-acme-provisioner
`}</code></pre>
    <p>{`Sign a CSR using the step CA ACME server and an existing server
along with webroot mode to serve the challenges locally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca sign foo.csr foo.crt \\
  --provisioner my-acme-provisioner --webroot "./acme-www" \\
`}</code></pre>
    <p>{`Sign a CSR using the ACME protocol served by another online CA (not step CA,
e.g. letsencrypt). NOTE: Let's Encrypt requires that the Subject Common Name
of a requested certificate be validated as an Identifier in the ACME order along
with any other SANS. Therefore, the Common Name must be a valid DNS Name. The
step CA does not impose this requirement.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca sign foo.csr foo.crt \\
--acme https://acme-staging-v02.api.letsencrypt.org/directory
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      